import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import useFetchAemBrspdContents from "../../../../shared/hooks/useFetchAemBrspdContents";
import { errorConditionGTM } from "../../Utils/googleTags";
import ApiUrls from "../../../../shared/utils/ApiUrls";

type loginSignupPageContentDetailsInitialStateType = {
  signupResetPageContent: any;
  loading: boolean;
  error: any;
};
const initialState: loginSignupPageContentDetailsInitialStateType = {
  signupResetPageContent: null,
  loading: false,
  error: null,
};
const host = window?.location?.host;
const file = "signup-reset.json";

export const getSignUpResetPageContent = createAsyncThunk(
  "get/SignupReset-page/content",
  async () => {
    try {
      const response = await useFetchAemBrspdContents({
        [ApiUrls.aem_content]: `${file}?appName=${host}`,
      });
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

const signupResetPageContentDetailSlice = createSlice({
  name: "signupReset-page/Details",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSignUpResetPageContent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSignUpResetPageContent.fulfilled, (state, action) => {
      state.signupResetPageContent = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getSignUpResetPageContent.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "signup reset contentstack",
        action.error?.message,
        action?.type
      );
      state.signupResetPageContent = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default signupResetPageContentDetailSlice.reducer;
