import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getShopExpAEMContent } from "../../../../shared/hooks/useShopExperience";
import API_URLS from "../../../../shared/utils/ApiUrls";
import store from "../store";

type shopExpContentInitialStateType = {
  contentData: any;
  loading: boolean;
};
const initialState: shopExpContentInitialStateType = {
  contentData: null,
  loading: false,
};

export const getShopExpContent = createAsyncThunk(
  "get/shopExp/AEM-content",
  async () => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const allShopExpAEM_Content_API = [
        getShopExpAEMContent({
          "": `${API_URLS?.aemShopExpHeaderURL}?appName=${window?.location?.host}`
        }),
        getShopExpAEMContent({
          "": `${API_URLS?.aemShopExpAccordionStepsURL}?appName=${window?.location?.host}`
        }),
        getShopExpAEMContent({
          "": `${API_URLS?.aemShopExpBuyflowFooterURL}?appName=${window?.location?.host}`
        }),
        getShopExpAEMContent({
          "": `${API_URLS?.aemShopExpAccordionsPageURL}?appName=${window?.location?.host}`
        }),
        getShopExpAEMContent({
          "": `${API_URLS?.aemShopExpInternetAccordionURL}?appName=${window?.location?.host}`
        }),
        getShopExpAEMContent({
          "": `${API_URLS?.aemShopExpVoiceListAndMoreContentApi}?appName=${window?.location?.host}`
        }),
        getShopExpAEMContent({
          "": `${API_URLS?.aemShopExpReviewOrderContentApi}?appName=${window?.location?.host}`
        }),
      ]
      const results = await Promise.allSettled(allShopExpAEM_Content_API);
      const responseObj: any = {};
      results.forEach((result, index) => {
        switch (index) {
          case 0:
            responseObj.shopExpHeaderContent = result?.status === "fulfilled" ? result?.value?.data : result?.reason || "";
            break;
          case 1:
            responseObj.shopExpAccordionStepsContent = result?.status === "fulfilled" ? result?.value?.data : result?.reason || "";
            break;
          case 2:
            responseObj.shopExpBuyflowFooterContent = result?.status === "fulfilled" ? result?.value?.data : result?.reason || "";
            break;
          case 3:
            responseObj.shopExpAccordionsPageContent = result?.status === "fulfilled" ? result?.value?.data : result?.reason || "";
            break;
          case 4:
            responseObj.shopExpInternetAccordionContent = result?.status === "fulfilled" ? result?.value?.data : result?.reason || "";
            break;
            case 5: 
             responseObj.shopExpVoiceListAndMoreContentApi = result?.status === "fulfilled" ? result?.value?.data : result?.reason || "";
             break;
             case 6: 
             responseObj.shopExpReviewOrderContentApi = result?.status === "fulfilled" ? result?.value?.data : result?.reason || "";
             break;
          default:
            break;
        }
      });
      return responseObj;
    } catch (error) {
      throw error;
    }
  }
);

const shopExpAEMContentSlice = createSlice({
  name: "accountSetting-page/Details",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getShopExpContent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getShopExpContent.fulfilled, (state, action) => {
      state.contentData = action.payload;
      state.loading = false;
    });
    builder.addCase(getShopExpContent.rejected, (state, action) => {
      state.contentData = null;
      state.loading = false;
    });
  },
});

export default shopExpAEMContentSlice.reducer;
