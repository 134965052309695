import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import useFetchAemBrspdContents from "../../../../shared/hooks/useFetchAemBrspdContents";
import { errorConditionGTM } from "../../Utils/googleTags";
import ApiUrls from "../../../../shared/utils/ApiUrls";

type paymentPageContentInitialStateType = {
  paymentPageContent: any;
  loading: boolean;
  error: any;
};
const initialState: paymentPageContentInitialStateType = {
  paymentPageContent: null,
  loading: false,
  error: null,
};
const host = window?.location?.host;
const file = "payment-page.json";

export const getPaymentPageContent = createAsyncThunk(
  "get/payment-page/content",
  async () => {
    try {
      const response = await useFetchAemBrspdContents({
        [ApiUrls.aem_content]: `${file}?appName=${host}`,
      });
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

const paymentContentDetailSlice = createSlice({
  name: "payment-page/Details",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPaymentPageContent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentPageContent.fulfilled, (state, action) => {
      state.paymentPageContent = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getPaymentPageContent.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "payment contentstack",
        action.error?.message,
        action?.type
      );
      state.paymentPageContent = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default paymentContentDetailSlice.reducer;
