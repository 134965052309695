import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import fetchContent from "../../../../shared/hooks/useFetchContent";
import {
  ACP_FORM_CONTENT,
  contentApiHeader,
  contentApiParams,
} from "../../../../shared/utils/Constants";
import useFetchAemBrspdContents from "../../../../shared/hooks/useFetchAemBrspdContents";
import ApiUrls from "../../../../shared/utils/ApiUrls";
import { errorConditionGTM } from "../../Utils/googleTags";

type acpFormContentDetailsInitialStateType = {
  acpFormPageContent: any;
  loading: boolean;
  error: any;
};
const initialState: acpFormContentDetailsInitialStateType = {
  acpFormPageContent: null,
  loading: false,
  error: null
};
const host = window?.location?.host;
const file = 'acp-signup.json'

export const getAcpFormPageContent = createAsyncThunk(
  "get/acpFormScreen-page/content",
  async () => {
    try {
      const response = await useFetchAemBrspdContents({
        [ApiUrls.aem_content]: `${file}?appName=${host}`,
      });
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);


const acpFormScreenContentDetailSlice = createSlice({
  name: "acpFormScreen-page/Details",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getAcpFormPageContent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAcpFormPageContent.fulfilled, (state, action) => {
      state.acpFormPageContent = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAcpFormPageContent.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "acp form contentstack",
        action.error?.message,
        action?.type
      );
      state.acpFormPageContent = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});
export default acpFormScreenContentDetailSlice.reducer;
