import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import API_URLS from "../../../../shared/utils/ApiUrls";
import {getNotification
} from "../../../../shared/hooks/useFetchNotifications";
import store from "../store";
type notificationInitialStateType = {
  notificationViewSortedData: any[];
  notificationArr: any[];
  isReaded: boolean;
  isMsgReaded: number;
  notificationResult?: any;
  notificationLoading:boolean;
  notificationError?:any;
  notificationPanelData?:any
};
const initialState: notificationInitialStateType = {
  notificationViewSortedData: [],
  notificationArr: [],
  isReaded: false,
  isMsgReaded: -1,
  notificationResult: null,
  notificationLoading:false,
  notificationError:null,
  notificationPanelData:[],
};

export const getNotificationByBan = createAsyncThunk(
  "notification/byban",
  async (banNumber: any) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const ban = banNumber || reduxStore?.user?.loginUserBan;
      /* In case if need to pass serive addressid to API. 
      const addId = reduxStore?.user?.loginUserAddressId;
       &addressId=${addId} */
      const response = await getNotification(
        {
          [API_URLS?.accountManagementUrl]: `ban-notification?ban=${ban}`, 
          
        },
        headers
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);
const notificationSlice = createSlice({
  name: "notification/Details",
  initialState,
  reducers: {
    setNotificationModelSortedData: (state, action) => {
      state.notificationViewSortedData = action.payload;
    },
    setNotificationPanelData:(state,action)=>{
      state.notificationPanelData = action.payload;
    },
    updateNotificationModelSortedData: (state, action) => {
      //state.notificationViewSortedData = action.payload;
    },
    setIsReaded: (state, action) => {
      state.isReaded = action.payload;
    },
    setIsMsgReaded: (state, action) => {
      state.isMsgReaded = action.payload;
    },
    setNotification: (state, action) => {
      state.notificationArr.push(action.payload);
    },
    clearNotification: (state) => {
      state.notificationArr = [];
      state.notificationViewSortedData = [];
    },
    notificationSliceSetInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getNotificationByBan.pending, (state) => {
      state.notificationLoading = true;
    });
    builder.addCase(getNotificationByBan.fulfilled, (state, action) => {
      state.notificationResult = action.payload;
      state.notificationLoading = false;
      state.notificationError = null;
    });
    builder.addCase(getNotificationByBan.rejected, (state, action) => {
      state.notificationLoading = false;
      state.notificationError = action.error;
    });
  }
});

export const {
  setNotificationModelSortedData,
  updateNotificationModelSortedData,
  setNotification,
  clearNotification,
  setIsReaded,
  setIsMsgReaded,
  notificationSliceSetInitialState,
  setNotificationPanelData
} = notificationSlice.actions;
export default notificationSlice.reducer;
