import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import useFetchAemBrspdContents from "../../../../shared/hooks/useFetchAemBrspdContents";
import { errorConditionGTM } from "../../Utils/googleTags";
import ApiUrls from "../../../../shared/utils/ApiUrls";

type vacationPauseServiceDetailsInitialStateType = {
  vacationPauseServicePageContent: any;
  loading: boolean;
  error: any;
};
const initialState: vacationPauseServiceDetailsInitialStateType = {
  vacationPauseServicePageContent: null,
  loading: false,
  error: null,
};
const host = window?.location?.host;
const file = "vacation-pause-service.json";

export const getVacationPauseServicePageContent = createAsyncThunk(
  "get/vacationPauseService-page/content",
  async () => {
    try {
      const response = await useFetchAemBrspdContents({
        [ApiUrls.aem_content]: `${file}?appName=${host}`,
      });
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

const vacationPauseServiceDetailSlice = createSlice({
  name: "vacationPauseService-page/Details",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVacationPauseServicePageContent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getVacationPauseServicePageContent.fulfilled,
      (state, action) => {
        state.vacationPauseServicePageContent = action.payload;
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(
      getVacationPauseServicePageContent.rejected,
      (state, action) => {
        errorConditionGTM(
          "BRSPD_Fiber_EC_Flow",
          "Vacation pause service content",
          action.error?.message,
          action?.type
        );
        state.vacationPauseServicePageContent = null;
        state.loading = false;
        state.error = action.error;
      }
    );
  },
});

export default vacationPauseServiceDetailSlice.reducer;
