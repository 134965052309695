import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchOrderDetails, getV2InternetProduct, getVoiceProduct } from "../../../../shared/hooks/useFetchServiceDetails";
import API_URLS from "../../../../shared/utils/ApiUrls";
import store from "../store";

type getProductCategoriesStateType = {
  productResponse: any;
  internetProductLoading: boolean;
  voiceProductLoading: boolean;
  existingProductLoading: boolean;
  error: any;
  productVoiceRes:any;
  voipProducts:any;
  baseline:any;
  address:any;
  existingOrderDetails:any;
};
const initialState: getProductCategoriesStateType = {
  productResponse: {},
  error: null,
  productVoiceRes: {},
  voipProducts: null,
  baseline: {},
  address: {},
  existingOrderDetails: null,
  internetProductLoading: false,
  voiceProductLoading: false,
  existingProductLoading: false
};
export const getV2InternetProductData = createAsyncThunk(
  "product/shopExp",
  async () => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const { sessionId } = reduxStore?.user;
      const siteId=reduxStore?.addressQualification?.data?.addressQualification?.entity
      ?.siteId;
      const wireCenter =
        reduxStore?.addressQualification?.data?.addressQualification?.entity
          ?.wireCenter;
      const mmmCode =
        reduxStore?.addressQualification?.data?.addressQualification?.entity
          ?.marketingProfile?.mmmCode;
      const uploadSpeed =
        reduxStore?.addressQualification?.data?.addressQualification?.entity
          ?.qualificationDetails?.maxUpBandwidth;
      const downloadSpeed =
        reduxStore?.addressQualification?.data?.addressQualification?.entity
          ?.qualificationDetails?.maxDownBandwidth;
      const affinityOccupation = reduxStore?.planDetails?.planDetails?.discountPlans[0]?.affinityOccupation;
         
      const productsRequest = {
        propertyId: reduxStore?.addressQualification?.data?.entity?.propertyId,
        fullAddress: reduxStore?.addressQualification?.data?.addressQualification?.entity?.fullAddress,
        instantInternet: reduxStore?.addressQualification?.data?.addressQualification?.entity?.instantInternet,
        mmmCode: mmmCode,
        uploadSpeed: uploadSpeed,
        downloadSpeed: downloadSpeed,
        customerType: null,
        marketSegment: "",
        qualForExternalVideo: false,
        
        customerGroupType: affinityOccupation,
      };
      const payload = {
        baseline: {
          transactionId: (siteId === 'bs') ? `brspd-${sessionId}` : (siteId === 'ctl') ? `copper-${sessionId}` : `${siteId}-${sessionId}`,
          "partnerOrderId": (siteId === 'bs') ? `brspd-${sessionId}` : (siteId === 'ctl') ? `copper-${sessionId}` : `${siteId}-${sessionId}`,
          partnerReferenceId: "",
          agentId:"",
          siteId: siteId,
          wirecenter: wireCenter,
          intent: '',
          channel: 'dotcom',
        },
        productsRequest
      };
      const params = {
        "": [API_URLS?.getV2InternetProduct],
      };
      const response = await getV2InternetProduct(params, payload, headers);
      if(response?.data?.messageStatus[0]?.status === 500){
        throw response?.data?.messageStatus[0]?.statusMessage
      }else{
        return response.data;
      }
      
    } catch (error) {
      throw error;
    }
  }
);


export const fetchOrderDetailsv2 = createAsyncThunk(
  "product/shopExpv2",
  async () => {
    try {
      
      const reduxStore = store.getState();
      const siteId=reduxStore?.addressQualification?.data?.addressQualification?.entity?.siteId;
      const headers = reduxStore?.brightspeedToken?.headers;
      const { sessionId } = reduxStore?.user;
      const BAN= reduxStore?.user?.data?.billingAccountNumber
      const orderPayload = {
        "transactionId": (siteId === 'bs') ? `brspd-${sessionId}` : (siteId === 'ctl') ? `copper-${sessionId}` : `${siteId}-${sessionId}`,
        "accountNumber": BAN
      }
      const params = {
        "": [API_URLS?.zuoraProductDetailsvoice],
      };
      const response = await fetchOrderDetails(params, orderPayload, headers);
      return response.data;
    }
    catch (error) {
      throw error;
    } 
  }
);
export const getV2VoiceProducts = createAsyncThunk(
  "voiceproduct/shopExp",
  async () => {
    
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const { sessionId } = reduxStore?.user;
      const siteId=reduxStore?.addressQualification?.data?.addressQualification?.entity?.siteId;
      const wireCenter =
        reduxStore?.addressQualification?.data?.addressQualification?.entity
          ?.wireCenter;
      // const  transactionId=  (siteId === 'bs') ? `brspd-${sessionId}` : (siteId === 'ctl') ? `copper-${sessionId}` : `${siteId}-${sessionId}`;
      const productRequest = {
        productCode: "",
        wireCenter: wireCenter,
        addressDetailId: reduxStore?.addressQualification?.data?.addressQualification?.entity?.addressId,
        "billingType": "prepaid",
        "promoCode": "",
        "partnerOrderId": reduxStore?.addressQualification?.data?.addressQualification?.entity?.partnerSessionId,
        interestedInSelfInstallOrTechInstall: "Tech",
        "propertyId": reduxStore?.addressQualification?.data?.addressQualification?.entity?.propertyId,
        "customerType":"" ,
        "lineType": "",
        "speed": ""
      }
      const payload = {
        baseline: {
          "transactionId": (siteId === 'bs') ? `brspd-${sessionId}` : (siteId === 'ctl') ? `copper-${sessionId}` : `${siteId}-${sessionId}`,
          channel: process.env.REACT_APP_CHANNEL_ID,
          siteId: headers?.siteId
            ? ["qf", "qf-ii", "quantum fiber"]?.includes(
                headers?.siteId?.toLowerCase()
              )
              ? "qf"
              : headers?.siteId
            : "", 
            wireCenter: wireCenter,
            intent: '',
            partnerOrderId: reduxStore?.addressQualification?.data?.addressQualification?.entity?.partnerSessionId,
        },
        productRequest
      };
      
      const params = {
        "": [API_URLS?.getVoiceProductUrl],
      };
      const response = await getVoiceProduct(params, payload, headers);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const getProductCategoriesSlice = createSlice({
  name: "product/getV2InternetProduct",
  initialState,
  reducers: {
    clearProductCategoriesSlice: (state) => {
      return initialState;
    },
    productCategoriesSliceSetInitialState: () => initialState,
    setExistingOrderDetails:(state, action: PayloadAction<any>) =>{
      return {
        ...state,
        existingOrderDetails: action.payload
      }
    },
    setVoipProducts: (state, action: PayloadAction<boolean>) => {
      state.voipProducts = action.payload;
    },
    setBaseline: (state, action: PayloadAction<any>) => {
      state.baseline = action.payload;
    },
    setAddress: (state, action: PayloadAction<any>) => {
      state.address = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getV2InternetProductData.pending, (state) => {
      state.internetProductLoading = true;
    });
    builder.addCase(getV2InternetProductData.fulfilled, (state, action) => {
      state.productResponse = action.payload;
      state.internetProductLoading = false;
      state.error = null;
    });
    builder.addCase(getV2InternetProductData.rejected, (state, action) => {
      state.productResponse = null;
      state.internetProductLoading = false;
      state.error = action.error;
    });

    builder.addCase(getV2VoiceProducts.pending, (state) => {
      state.voiceProductLoading = true;
    });
    builder.addCase(getV2VoiceProducts.fulfilled, (state, action) => {
      state.productVoiceRes = action.payload;
      state.voiceProductLoading = false;
      state.error = null;
    });
    builder.addCase(getV2VoiceProducts.rejected, (state, action) => {
      state.productVoiceRes = null;
      state.voiceProductLoading = false;
      state.error = action.error;
    });

    builder.addCase(fetchOrderDetailsv2.pending, (state) => {
      state.existingProductLoading = true;
    });
    builder.addCase(fetchOrderDetailsv2.fulfilled, (state, action) => {
      state.existingOrderDetails = action.payload;
      state.existingProductLoading = false;
      state.error = null;
    });
    builder.addCase(fetchOrderDetailsv2.rejected, (state, action) => {
      state.existingOrderDetails = null;
      state.existingProductLoading = false;
      state.error = action.error;
    });
  },
});
export const {
  setExistingOrderDetails,
  setVoipProducts,
  setBaseline,
  setAddress
} = getProductCategoriesSlice.actions;
export default getProductCategoriesSlice.reducer;
