import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { getNonAuthPaymentArrangements } from "../../../../shared/hooks/useFetchPaymentArrangements";
import store from "../store";

type nonAuthPaymentArrangementInitialStateType = {
  data: any;
  currentStep: number;
  selectedPayArrangmentType: string;
  selectedPaymentOption: string;
  nonAuthEmailId: string;
  nonAuthHashEmailId: string;
  nonAuthContactNumber: string;
  nonAuthPrincipalName: string;
  callBackUrlAfterNonAuthValidateOTP: string;
  nonAuthCommunicationPreference: string;
  paymentArrangementLoading: boolean;
  paymentArrangementData: any;
  paymentArrangementError: any;
  paymentCardInfo: any;
  selectedPaDate:any;
  enteredPayAmount:any;
  autopayRemovedBan:boolean;
  remainingPayment:any;
  paymentAuthID:any;
  autopayRemoveModal:boolean;
  selectedPaymentType:any;
  selectedValueCredit:any;
  selectedValueBank:any;
  isTermAndCondition:boolean;
  cardCvv:any;
  isNonAuthPa:any;
  isModalVisible:any;
  paymentIssueModalUserData:any;
  planDetails:any,
  isUserIneligible:any,
  paFlag:boolean,
  
};
const initialState: nonAuthPaymentArrangementInitialStateType = {
  data: null,
  currentStep: 1,
  selectedPayArrangmentType: "",
  selectedPaymentOption: "",
  nonAuthEmailId: "",
  nonAuthHashEmailId: "",
  nonAuthContactNumber: "",
  nonAuthPrincipalName: "",
  callBackUrlAfterNonAuthValidateOTP: "",
  nonAuthCommunicationPreference: "",
  paymentArrangementLoading: false,
  paymentArrangementData: null,
  paymentArrangementError: null,
  paymentCardInfo: null,
  selectedPaDate:null,
  enteredPayAmount:null,
  autopayRemovedBan:false,
  remainingPayment:null,
  paymentAuthID:null,
  autopayRemoveModal:false,
  selectedPaymentType:"",
  selectedValueCredit:"",
  selectedValueBank:"",
  isTermAndCondition: false,
  cardCvv:false,
  isNonAuthPa:false,
  isModalVisible:"",
  paymentIssueModalUserData:null,
  planDetails:null,
  isUserIneligible:null,
  paFlag:false
};

export const getNonAuthPaymentArrangement = createAsyncThunk(
  "payment/arrangement",
  async (billingAccountNumber: string) => {
    const reduxStore = store.getState();
    const headers = reduxStore?.brightspeedToken?.headers;
     try {
      const response = await getNonAuthPaymentArrangements(
        {
          "": API_URLS?.non_auth_paymentArrangements,
        },
        { ban: billingAccountNumber },
        headers
      );
      return response?.data; 
    } catch (error) {
      throw error;
    }
  }
);

const nonAuthPaymentArrangementSlice = createSlice({
  name: "nonAuthPaymentArrangement/Slice",
  initialState,
  reducers: {
    updateCurrentSate: (state, action) => {
      state.currentStep = action.payload;
    },
    updateSelectedPayArrangmentType: (state, action) => {
      state.selectedPayArrangmentType = action.payload;
    },
    updateSelectedPaymentOption: (state, action) => {
      state.selectedPaymentOption = action.payload;
    },
    updateNonAuthEmailId: (state, action) => {
      state.nonAuthEmailId = action.payload;
    },
    updateNonAuthHashEmailId: (state, action) => {
      state.nonAuthHashEmailId = action.payload;
    },
    updateNonAuthContactNumber: (state, action) => {
      state.nonAuthContactNumber = action.payload;
    },
    updateNonAuthPrincipalName: (state, action) => {
      state.nonAuthPrincipalName = action.payload;
    },
    updateCallBackUrlAfterNonAuthValidateOTP: (state, action) => {
      state.callBackUrlAfterNonAuthValidateOTP = action.payload;
    },
    updateNonAuthCommunicationPreference: (state, action) => {
      state.nonAuthCommunicationPreference = action.payload;
    },
    updatePaymentCardInfo: (state, action) => {
      state.paymentCardInfo = action.payload;
    },
    updateSelectedDate: (state, action) => {
      state.selectedPaDate = action.payload;
    },
    updateEnteredPayAmount: (state, action) => {
      state.enteredPayAmount = action.payload;
    },
    banAutoPayUpdate:(state,action)=>{
      state.autopayRemovedBan = action.payload;
    },
    banAutoPayUpdateModal:(state,action)=>{
      state.autopayRemoveModal = action.payload;
    },
    updateRemainingPayment:(state,action)=>{
      state.remainingPayment = action.payload;
    },
    updatePaymentAuthID:(state,action)=>{
      state.paymentAuthID = action.payload;
    },
    setSelectedPaymentType:(state,action)=>{
      state.selectedPaymentType = action.payload;
    },
    setSelectedValueCredit:(state,action)=>{
      state.selectedValueCredit = action.payload;
    },
    setSelectedValueBank:(state,action)=>{
      state.selectedValueBank = action.payload;
    },
    setTermAndCondition:(state,action)=>{
      state.isTermAndCondition = action.payload;
    }, 
    setCardCvv:(state,action)=>{
      state.cardCvv = action.payload;
    },
    updateIsNonAuthPa:(state,action)=>{
      state.isNonAuthPa = action.payload;
    },
    setIsModalVisible:(state,action)=>{
      state.isModalVisible = action.payload;
    },
    setPaymentIssueModalUserData:(state,action)=>{
      state.paymentIssueModalUserData = action.payload;
    },
    setPlanDetails:(state,action)=>{
      state.planDetails = action.payload;
    },
    setIsUserIneligible:(state,action)=>{
      state.isUserIneligible = action.payload;
    },
    
    resetState: () => initialState, // Add this reducer to reset state
  },
  extraReducers: (builder) => {
    builder.addCase(getNonAuthPaymentArrangement.pending, (state) => {
      state.paymentArrangementLoading = true;
    });
    builder.addCase(getNonAuthPaymentArrangement.fulfilled, (state, action) => {
      state.paymentArrangementData = action.payload;
      state.paymentArrangementLoading = false;
      state.paymentArrangementError = null;
    });
    builder.addCase(getNonAuthPaymentArrangement.rejected, (state, action) => {
      state.paymentArrangementData = null;
      state.paymentArrangementLoading = false;
      state.paymentArrangementError = action.error;
    });
  },
});

export const {
  updateCurrentSate,
  updateSelectedPayArrangmentType,
  updateSelectedPaymentOption,
  updateNonAuthEmailId,
  updateNonAuthHashEmailId,
  updateNonAuthContactNumber,
  updateNonAuthPrincipalName,
  updateCallBackUrlAfterNonAuthValidateOTP,
  updateNonAuthCommunicationPreference,
  updatePaymentCardInfo,
  updateSelectedDate,
  updateEnteredPayAmount,
  banAutoPayUpdate,
  updateRemainingPayment,
  updatePaymentAuthID,
  banAutoPayUpdateModal,
  setSelectedPaymentType,
  setSelectedValueBank,
  setSelectedValueCredit,
  setTermAndCondition,
  setCardCvv,
  updateIsNonAuthPa,
  resetState,
  setIsModalVisible,
  setPlanDetails,
  setPaymentIssueModalUserData,
  setIsUserIneligible,
} = nonAuthPaymentArrangementSlice.actions;
export default nonAuthPaymentArrangementSlice.reducer;
