import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchPaymentHistory, fetchPaymentHistoryForPA } from "../../../../shared/hooks/useFetchPaymentHistory";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";
import ApiUrls from "../../../../shared/utils/ApiUrls";
import { EC_DW_LOGGING_STEPS } from "../../../../shared/utils/Constants";
import DwLogutil from "../../Utils/DwLogutil";

type paymentHistoryPAInitialStateType = {
  paymentHistory: any;
  loading: boolean;
  error: any;
  fetched: boolean
};
const initialState: paymentHistoryPAInitialStateType = {
  paymentHistory: null,
  loading: false,
  error: null,
  fetched: false
};

export const getPaymentHistoryForPA = createAsyncThunk(
  "payment/historypa",
  async (billingAccountNumber: string) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const param={
        "": ApiUrls?.paymentHistoryUrlForPA_QA,
    }
      const body={
        "ban": billingAccountNumber
      }
      const response = await fetchPaymentHistoryForPA(
        param,
        body,
        headers
      );

      const isLongPending = (str:string) => {
        if( !(str && str.length === 8) ) return false;
        
        const dateFormat = `${str.slice(0, 4)}-${str.slice(4, 6)}-${str.slice(-2)}`;
        if(new Date() > new Date(dateFormat)){
          return false
        }
        return true;
      }

      if(response?.data) {
        const installments = response?.data?.treatment?.[0]?.installment
        for(const installment of installments){
          if(installment?.status === 'pending' && isLongPending(installment?.date)){
            DwLogutil({ stepName: EC_DW_LOGGING_STEPS.pa_date_incorrect, error: 'Installment Date incorrect'});
          }
        }

        return response.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

const paymentHistoryForPASlice = createSlice({
  name: "payment/historypa",
  initialState,
  reducers: {
    clearPaymentHistoryForPASlice: (state) => {
      return initialState;
    },
    paymentHistoryForPASliceSetInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getPaymentHistoryForPA.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentHistoryForPA.fulfilled, (state, action) => {    
      state.paymentHistory = action.payload;
      state.loading = false;
      state.error = null;
      state.fetched= true;
    });
    builder.addCase(getPaymentHistoryForPA.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "payment history",
        action.error?.message,
        action?.type
      );
      state.paymentHistory = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { clearPaymentHistoryForPASlice, paymentHistoryForPASliceSetInitialState } =
  paymentHistoryForPASlice.actions;
export default paymentHistoryForPASlice.reducer;
