import ApiService from "../utils/ApiService";
import { getEnvironment } from "../utils/EnvConfig";

const pauseService = async (params = {}, body = {}, headers) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    { headers: headers }
  );
};

export const getShopExpAEMContent = async (params = {}, headers) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env.aem_content_url },
    { headers: headers }
  );
} 

export {pauseService};
